import React, { useState } from 'react';
import Navigation from '../components/navigation';
import Footer from '../components/footer';
import '../styles/contact.css'; // Make sure you have a contact.css file for styling

function Contact() {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    company: '',
    email: '',
    phone: '',
    productInterest: '',
    comments: '', // New field for additional comments
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.name || !formData.company || !formData.email || !formData.phone || !formData.productInterest) {
      alert('Please fill out all required fields.');
      return;
    }
    setIsLoading(true)
    try {
      const response = await fetch('https://server7845.nextbotswana.com/api/request-demo', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      setIsLoading(false);

      if (response.ok) {
        // alert('Thank you! We have received your request and will reach out as soon as possible.');
        setIsSuccess(true);
        setIsError(false);
        setFormData({ name: '', company: '', email: '', phone: '', productInterest: '', comments: '' });
      } else {
        setIsSuccess(false);
        setIsError(true);
        // alert('Failed to send the request. Please try again later.');
      }
    } catch (error) {
      // alert('Error sending the request.');
      setIsError(true);
      setIsSuccess(false);
      setIsLoading(false);
      console.error(error);
    }
  };

  return (
      <>
        <Navigation />
        <div className="contact-page">
          <div className="contact-container">
            <h3>Get in Touch with Us</h3>
            <p>
              Thank you for your interest in our services! Please fill out the form below to request a demo or inquire about our services.
              We’ll reach back to you as soon as possible. You can also contact us directly via email at{' '}
              <a href="mailto:nextbotswana@gmail.com">nextbotswana@gmail.com</a> or call us at <a href='tel:+267 72 672 208'>+267 72 672 208</a>.
            </p>

            <div className='notify'>
                {
                  isSuccess && 
                  <div className="success-message">Thank you! We have received your message and will reach out as soon as possible.</div>
                }
                {
                  isError &&
                  <div className="error-message">Failed to send the message. Please try again later or contact us via email directly.</div>
                }
            </div>
            <form className="contact-form" onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="name">Name<span className="required">*</span></label>
                <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                    placeholder='Name'
                    disabled={isLoading}
                />
              </div>
              <div className="form-group">
                <label htmlFor="company">Company<span className="required">*</span></label>
                <input
                    type="text"
                    id="company"
                    name="company"
                    value={formData.company}
                    onChange={handleChange}
                    required
                    placeholder='Company'
                    disabled={isLoading}
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email<span className="required">*</span></label>
                <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                    placeholder='Email'
                    disabled={isLoading}
                />
              </div>
              <div className="form-group">
                <label htmlFor="phone">Phone Number<span className="required">*</span></label>
                <input
                    type="text"
                    id="phone"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    required
                    placeholder='Phone Number'
                    disabled={isLoading}
                />
              </div>
              <div className="form-group">
                <label htmlFor="productInterest">Interested In<span className="required">*</span></label>
                <select
                    id="productInterest"
                    name="productInterest"
                    value={formData.productInterest}
                    onChange={handleChange}
                    required
                    disabled={isLoading}
                >
                  <option value="">Select a service...</option>
                  <optgroup label="Marketing & Advertising Services">
                    <option value="Market Analysis">Market Analysis</option>
                    <option value="Targeted Advertising">Targeted Advertising</option>
                    <option value="Brand Strategy">Brand Strategy</option>
                  </optgroup>
                  <optgroup label="Software and AI Integration Services">
                    <option value="AI Solutions">AI Solutions</option>
                    <option value="Custom Software Development">Custom Software Development</option>
                    <option value="Data Analytics">Data Analytics</option>
                  </optgroup>
                </select>
              </div>
              {/* Optional Comments Section */}
              <div className="form-group">
                <label htmlFor="comments">Additional Comments (Optional)</label>
                <textarea
                    id="comments"
                    name="comments"
                    value={formData.comments}
                    onChange={handleChange}
                    placeholder="Message"
                    rows={4}
                    disabled={isLoading}
                ></textarea>
              </div>
              <button type="submit" className="submit-button" disabled={isLoading}>
                {isLoading ? "Submitting..." : "Submit"}
              </button>
            </form>
          </div>
        </div>
        <Footer />
      </>
  );
}

export default Contact;
